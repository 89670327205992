document.addEventListener("turbo:load", function () {
  var heardToggles = document.getElementsByName("visit[heard_from_us]");
  var heardOtherToggle = document.querySelector("#visit_heard_from_us_other");
  var heardText = document.querySelector("#heard_from_us_other_text");

  heardToggles.forEach((toggle) => {
    toggle.addEventListener("change", (e) => {
      if (toggle.value == "other") {
        heardText.style.display = "block";
      } else {
        heardText.style.display = "none";
      }
    });
  });

  if (heardText) {
    heardText.addEventListener("keyup", (e) => {
      heardOtherToggle.value = heardText.value;
    });
  }

  var reasonToggles = document.getElementsByName("visit[reason]");
  var reasonOtherToggle = document.querySelector("#visit_reason_other");
  var reasonText = document.querySelector("#reason_other_text");

  reasonToggles.forEach((toggle) => {
    toggle.addEventListener("change", (e) => {
      if (toggle.value == "other") {
        reasonText.style.display = "block";
      } else {
        reasonText.style.display = "none";
      }
    });
  });

  if (reasonText) {
    reasonText.addEventListener("keyup", (e) => {
      reasonOtherToggle.value = reasonText.value;
    });
  }
});
