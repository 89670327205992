import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["trueSelection", "falseSelection", "input"];
  static values = {
    active: String,
    inactive: String,
  };

  connect() {
    BlocksColorsAdjustment();
  }

  falseSelectionTargetConnected(target) {
    target.addEventListener("click", (e) => {
      this.inputTarget.value = false;
      this.inactiveValue.split(" ").forEach((value) => {
        this.falseSelectionTarget.classList.remove(value);
      });
      this.activeValue.split(" ").forEach((value) => {
        this.falseSelectionTarget.classList.add(value);
      });

      this.inactiveValue.split(" ").forEach((value) => {
        this.trueSelectionTarget.classList.add(value);
      });
      this.activeValue.split(" ").forEach((value) => {
        this.trueSelectionTarget.classList.remove(value);
      });
      BlocksColorsAdjustment();
    });
  }

  trueSelectionTargetConnected(target) {
    target.addEventListener("click", (e) => {
      this.inputTarget.value = true;
      this.inactiveValue.split(" ").forEach((value) => {
        this.falseSelectionTarget.classList.add(value);
      });
      this.activeValue.split(" ").forEach((value) => {
        this.falseSelectionTarget.classList.remove(value);
      });

      this.inactiveValue.split(" ").forEach((value) => {
        this.trueSelectionTarget.classList.remove(value);
      });
      this.activeValue.split(" ").forEach((value) => {
        this.trueSelectionTarget.classList.add(value);
      });
      BlocksColorsAdjustment();
    });
  }
}

function BlocksColorsAdjustment() {
  const counter = document.querySelectorAll(
    'input[data-options-radio-target="input"][value="true"]'
  ).length;
  block1 = document.getElementById("block_1");
  block2 = document.getElementById("block_2");
  block3 = document.getElementById("block_3");
  block4 = document.getElementById("block_4");
  block5 = document.getElementById("block_5");

  block1.hidden = counter != 0;
  block2.hidden = counter != 1;
  block3.hidden = counter != 2;
  block4.hidden = counter != 3;
  block5.hidden = counter != 4;
}
