document.addEventListener("turbo:load", function () {
  let read_button = document.querySelector(".js-read-notification");
  let goto_button = document.querySelector(".js-goto-notification");

  if (read_button) {
    var container = read_button.closest(".notification");
    var url = read_button.closest("form").action;
    setTimeout(function () {
      UpdateReadAt(container, url);
    }, 10000);

    document
      .querySelector(".js-read-notification")
      .addEventListener("click", function (e) {
        e.preventDefault();
        var container = document
          .querySelector(".js-read-notification")
          .closest(".notification");
        var url = document
          .querySelector(".js-read-notification")
          .closest("form").action;
        UpdateReadAt(container, url);
      });
  }

  if (goto_button) {
    document
      .querySelector(".js-goto-notification")
      .addEventListener("click", function (e) {
        e.preventDefault();
        var container = document
          .querySelector(".js-goto-notification")
          .closest(".notification");
        var read_button = container.querySelector(".js-read-notification");
        var url = read_button.closest("form").action;
        var redirect = document.querySelector(".js-goto-notification").href;
        UpdateReadAt(container, url, redirect);
      });
  }

  function UpdateReadAt(container, url, redirect) {
    var token = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");
    var notifications = document.getElementById("notifications");
    var url_fragments = url.split("?");
    url = url_fragments[0] + ".json";
    fetch(url, {
      method: "PUT",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response;
      })
      .then((data) => {
        RemoveNotification(container, notifications);
        if (redirect) window.location.href = redirect;
      })
      .catch((error) => {
        console.log(error);
        RemoveNotification(container, notifications);
      });
  }

  function RemoveNotification(container, notifications) {
    container.remove();
    if (notifications.children.length == 0)
      notifications.classList.add("hidden");
  }
});
