import consumer from "./consumer"

document.addEventListener("turbo:load", function () {

  let displayRoom = document.getElementById('display-room');
  if (displayRoom) {
    let roomId = displayRoom.dataset['roomId'] || null;

    if (!App.displays[roomId]) {

      App.displays[roomId] = consumer.subscriptions.create({ channel: "DisplayChannel", room_id: roomId }, {
        connected: function () {
          // console.log("Connected to display " + roomId);
        },

        disconnected: function () {
          // console.log("Disconnected from display " + roomId);
        },

        received: function (data) {
          switch (data['type']) {
            case 'reload':
              window.location.href = window.location.href;
              break;
          }
        }
      });

    }
  }
});