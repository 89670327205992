document.addEventListener("turbo:load", function () {
  let element = document.getElementById("customer_request_group_size");

  if (typeof element != "undefined" && element != null) {
    element.addEventListener("blur", updateVisitors);

    function updateVisitors() {
      let attributes = updateParams();
      let params = attributes.params;
      params["visitors"] = document.getElementById(
        "customer_request_group_size"
      ).value;
      updateUrl(attributes.link, attributes.url, params);
    }
  }

  let date = document.getElementById("customer_request_date");

  if (typeof date != "undefined" && date != null) {
    date.addEventListener("blur", updateDate);

    function updateDate() {
      let attributes = updateParams();
      let params = attributes.params;
      params["date"] = new Date(
        document.getElementById("customer_request_date").value
      )
        .toJSON()
        .slice(0, 10)
        .split("-")
        .reverse()
        .join("-");
      updateUrl(attributes.link, attributes.url, params);
    }
  }

  let time = document.getElementById("customer_request_time");

  if (typeof time != "undefined" && time != null) {
    time.addEventListener("blur", updateTime);

    function updateTime() {
      let attributes = updateParams();
      let params = attributes.params;
      params["time"] = document.getElementById("customer_request_time").value;
      updateUrl(attributes.link, attributes.url, params);
    }
  }

  function updateUrl(link, url, params) {
    var queryString = Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");
    link.href = `${url[0]}` + `?${queryString}`;
  }

  function updateParams() {
    let link = document.getElementById("visitor_update");
    url = link.href.split("?");
    params = Object.fromEntries(new URLSearchParams(url[1]));
    return { link, url, params };
  }
});
