import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["rotateable", "revealable", "startButton", "skipButton"];
  static values = { angle: String };

  connect() {}

  rotate() {
    this.startButtonTarget.classList.toggle("hidden");
    this.rotateableTarget.addEventListener("transitionend", () => {
      this.revealableTargets.forEach((target) => {
        target.classList.toggle("hidden");
      });
      this.skipButtonTarget.classList.toggle("hidden");
    });
    this.rotateableTarget.style.webkitTransitionDuration = "4s";
    this.rotateableTarget.style.webkitTransitionTimingFunction = "ease-out";
    this.rotateableTarget.style.webkitTransform =
      "rotate(" + this.angleValue + "deg)";
  }
}
