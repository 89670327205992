document.addEventListener("change", function () {
  var entries = document.getElementsByClassName("waitlist_entry");

  for (let i = 0; i < entries.length; i++) {
    entries[i].addEventListener("change", function () {
      start_time = document.getElementById("waitlist_entry_start_time");
      end_time = document.getElementById("waitlist_entry_end_time");
      hint = document.getElementById("waitlist_hint");

      if (start_time.value && end_time.value) {
        if (start_time.value >= end_time.value) {
          hint.classList.remove("hidden");
          document.getElementById("submit_button").disabled = true;
        } else {
          hint.classList.add("hidden");
          document.getElementById("submit_button").disabled = false;
        }
      }
    });
  }
});
