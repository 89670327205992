import consumer from "../source/consumer";

document.addEventListener("turbo:load", function () {
  let groupSelection = document.getElementById("group-selection");
  if (groupSelection) {
    let visitId = groupSelection.getAttribute("data-id");
    let currentVisitorId = groupSelection.getAttribute("data-visitor-id");
    let currentVisitorHasGroup = groupSelection.getAttribute(
      "data-visitor-has-group"
    );
    let group;
    let visitor;

    if (!App.visits[visitId]) {
      App.visits[visitId] = consumer.subscriptions.create(
        { channel: "VisitChannel", visit_id: visitId },
        {
          connected: function () {
            console.log("Connected to visit " + visitId);
          },
          disconnected: function () {
            console.log("Disconnected from visit " + visitId);
          },
          received: function (data) {
            switch (data["type"]) {
              case "select":
                group = document.getElementsByClassName(
                  "js-group-" + data["color"]
                );
                if (data["visitor_id"] == currentVisitorId) {
                  currentVisitorHasGroup = true;
                }
                visitor = data["visitor"];
                if (group && visitor) {
                  group[0].insertAdjacentHTML("beforeend", visitor);
                }
                this.reset_buttons(data);
                break;
              case "unselect":
                visitor = document.getElementsByClassName(
                  "js-visitor-" + data["visitor_id"]
                )[0];
                if (visitor) {
                  if (data["visitor_id"] == currentVisitorId) {
                    currentVisitorHasGroup = false;
                  }
                  visitor.remove();
                }
                this.reset_buttons(data);
                break;
            }
          },
          reset_buttons: function (data) {
            let groups = document.querySelectorAll(".js-group");
            groups.forEach(function (group) {
              let color = group.getAttribute("data-color");
              let seats = group.getAttribute("data-seats");
              let members = group.querySelectorAll(".group-member").length;
              let selectButton = group.querySelectorAll(
                ".js-select-" + color
              )[0];
              if (currentVisitorHasGroup || members >= seats) {
                selectButton.classList.add("hidden");
              } else {
                selectButton.classList.remove("hidden");
              }
            });
            let startButton = document.querySelector(
              ".js-group-selection-start"
            );
            if (startButton) {
              if (data["all_teams_filled"]) {
                startButton.classList.remove("hidden");
              } else {
                startButton.classList.add("hidden");
              }
            }
          },
        }
      );
    }
  }
});
