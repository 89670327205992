import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["success"];
  static values = { url: String };

  copy(event) {
    event.preventDefault();
    navigator.clipboard.writeText(this.urlValue).then(
      () => {
        this.successTargets.forEach((target) => {
          target.classList.remove("hidden");
        });
      },
      () => {
        // Handle error here
      }
    );
  }
}
