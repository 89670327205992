import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["btn", "tab"];
  static values = {
    defaultTab: String,
    activeClass: String,
    inactiveClass: String,
  };
  currentTab = null;

  connect() {
    this.hideAllTabs();

    try {
      const selectedBtn = this.findButtonById(this.defaultTabValue);
      const selectedTab = this.findTabById(this.defaultTabValue);

      this.showTab(selectedTab, selectedBtn);
      this.currentTab = selectedTab;
    } catch (error) {
      console.error("Error selecting default tab:", error);
    }
  }

  select(event) {
    const selectedTab = this.findTabById(event.currentTarget.id);

    if (selectedTab === this.currentTab) {
      return;
    }

    this.hideAllTabs();
    this.resetButtonStyles();
    this.showTab(selectedTab, event.currentTarget);

    this.currentTab = selectedTab;
  }

  hideAllTabs() {
    this.tabTargets.forEach((tab) => tab.classList.add("hidden"));
  }

  resetButtonStyles() {
    this.btnTargets.forEach((btn) =>
      btn.classList.remove(this.activeClassValue)
    );
    this.btnTargets.forEach((btn) =>
      btn.classList.add(this.inactiveClassValue)
    );
  }

  showTab(tab, btn) {
    tab.classList.remove("hidden");
    btn.classList.add(this.activeClassValue);
    btn.classList.remove(this.inactiveClassValue);
  }

  findButtonById(id) {
    return this.btnTargets.find((element) => element.id === id);
  }

  findTabById(id) {
    return this.tabTargets.find((element) => element.id === id);
  }
}
