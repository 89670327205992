import Pull from "pulljs";

document.addEventListener("turbo:load", function () {
  Pull.init({
    instructionsPullToRefresh: "Zum Aktualisieren ziehen",
    instructionsReleaseToRefresh: "Zum Aktualisieren loslassen",
    instructionsRefreshing: "Aktualisere...",
    onRefresh() {
      window.location.reload();
    },
  });
});
