import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  updateHref(event) {
    const isChecked = event.target.checked;
    var link = document.getElementById("pdf_offer_view");
    var url = new URL(link.href);

    if (isChecked) {
      url.searchParams.delete("use_minimum_price");
    } else {
      url.searchParams.set("use_minimum_price", "false");
    }
    // Update the href attribute of the link
    link.href = url.toString();
  }
}
