// Entry point for the build script in your package.json
import Rails from "@rails/ujs";
Rails.start();
import "@hotwired/turbo-rails";
import "./controllers";

var global = global || window;

import "./source/consumer.js";
import "./source/display.js";
import "./source/feedback.js";
import "./source/group_selection.js";
import "./source/notifications.js";
import "./source/pull_to_refresh.js";
import "./source/user.js";
import "./source/request_redirect.js";
import "./source/viewport_fix.js";
import "./source/waitlist_entry_time_validate.js";
import "./source/controlled_user.js";
import "./source/booking.js";
import "./source/lobby.js";
import "./source/auto_refresh.js";

window.App = {};
App.displays = [];
App.visits = [];
