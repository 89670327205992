document.addEventListener("turbo:load", function () {
  var isEveryoneReadyCheckbox = document.getElementById('is_everyone_ready');
  var greenLightCheckbox = document.getElementById('green_light');
  var setupButton = document.getElementById('setup_button');

  function checkCheckboxes() {
    if (isEveryoneReadyCheckbox.checked && (greenLightCheckbox === null || greenLightCheckbox.checked)) {
      setupButton.classList.remove('opacity-50', 'pointer-events-none');
    } else {
      setupButton.classList.add('opacity-50', 'pointer-events-none');
    }
  }

  if (isEveryoneReadyCheckbox) {
    isEveryoneReadyCheckbox.addEventListener('change', checkCheckboxes);
  }

  if (greenLightCheckbox) {
    greenLightCheckbox.addEventListener('change', checkCheckboxes);
  }
});
