document.addEventListener("turbo:load", function () {
  var images = document.getElementsByClassName("avatar")
  for (var i = 0; i < images.length; i++) {
    images[i].addEventListener("click", function (e) {
      var elements = document.getElementsByClassName("avatar")
      for (var i = 0; i < images.length; i++) {
        elements[i].style.border = 'none';
      }
      e.currentTarget.style.border = '3px solid red';
      e.preventDefault();
      document.getElementById("user_avatar_id").value = e.currentTarget.previousElementSibling.value;
    });
  }
});
