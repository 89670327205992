import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["emailField", "passwordResetLink"];

  updateLink() {
    let email = this.emailFieldTarget.value;
    let link = this.passwordResetLinkTarget;
    let url = new URL(link.href);
    url.searchParams.set("email", email);
    link.href = url.toString();
  }
}
