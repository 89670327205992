import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  password(e) {
    var password_field =
      document.getElementsByClassName("eye-password-field")[0];

    if (password_field.type === "password") {
      document.getElementsByClassName("eye_image")[0].classList.add("hidden");
      document
        .getElementsByClassName("eye_slash_image")[0]
        .classList.remove("hidden");
      password_field.type = "text";
    } else {
      document
        .getElementsByClassName("eye_image")[0]
        .classList.remove("hidden");
      document
        .getElementsByClassName("eye_slash_image")[0]
        .classList.add("hidden");
      password_field.type = "password";
    }
  }
}
