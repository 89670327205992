import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  suggest(event) {
    event.preventDefault();
    const value = event.currentTarget.textContent;
    const element = document.getElementById('user_username');
    element.value = value;
    element.focus();
    element.blur(); // This will remove focus from the element
  }
}
